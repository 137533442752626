import Audio from './modules/audio';
import Carousel from './modules/carousel';
import Gallery from './modules/gallery';
import Global from './modules/global';
import Header from './modules/header';
import Cmp from './modules/cmp';
import Commercial from './modules/commercial';
import Newsletter from './modules/newsletter';
import Livenow from './modules/livenow';
import ScrollTracker from './modules/scrolltracker';
import RFVTracker from './modules/rfv';

const modules = {
  Audio,
  Carousel,
  Gallery,
  Global,
  Header,
  Cmp,
  Commercial,
  Newsletter,
  Livenow,
  ScrollTracker,
  RFVTracker
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  var options = JSON.parse(elements[0].text || elements[0].textContent);
  options.modules.forEach((module) => {
    if (modules[module.name]) {
      new modules[module.name](module.options);
    }
  });
}
